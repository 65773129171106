.react-datepicker {
    background-color: white !important;
}

.react-datepicker-wrapper {
    position: absolute;
}

.crm__yearPicker {
    width: 160px;
    position: relative;
}